import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers.ts';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from '../../api/ParkingaboApi.tsx';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { useNavigate } from 'react-router-dom';
import {
    useNavigateOnError,
    useNavigateOnSuccess,
    useServerErrorEffect,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { useState } from 'react';
import { useParkingaboAuthedPathGeneration } from '../RouteUtils.tsx';
import { FeedbackPopup } from '../../components/FeedbackPopup.tsx';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider.tsx';

enum ValidationState {
    LOADING = 'LOADING',
    REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE = 'REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE',
    REDIRECT_TO_LOGIN_ONBOARDING_PENDING = 'REDIRECT_TO_LOGIN_ONBOARDING_PENDING',
    CONFIRM_EMAIL = 'CONFIRM_EMAIL',
    BADGE_NOT_FOUND = 'BADGE_NOT_FOUND',
    WRONG_CUSTOMER = 'WRONG_CUSTOMER',
    GENERIC_ERROR = 'GENERIC_ERROR',
}

export function usePublicBadgeValidationState(badgeLabelNr: string | null) {
    const [badgeValidationState] = useParkingaboServerFetch<
        {
            isOnboardingComplete: boolean;
            hasLogin: boolean;
            emailToBeConfirmed: string | null;
        },
        { badgeLabelNr: string },
        { reason: ValidationErrorReason.BADGE_NOT_FOUND }
    >(
        ({ badgeLabelNr }) => ({
            url: `/ui-api/parkingabo/badge/${badgeLabelNr}/validation`,
        }),
        badgeLabelNr ? { badgeLabelNr: badgeLabelNr } : null,
    );

    return [badgeValidationState];
}

export function PublicParkingaboBadgeValidationRoute() {
    const badgeLabelNr = useParamOrNull('badgeLabelNr');
    const navigate = useNavigate();
    const [validationState, setValidationState] = useState<ValidationState>(
        ValidationState.LOADING,
    );
    const [emailToBeConfirmed, setEmailToBeConfirmed] = useState<string | null>(
        null,
    );
    const [badgeValidationState] = usePublicBadgeValidationState(badgeLabelNr);

    const [anotherLinkState, requestAnotherLink] = useParkingaboServerWrite<
        { email: string },
        never
    >(() => ({
        url: '/ui-api/parkingabo/resend-verification-link',
    }));

    useNavigateOnSuccess(
        anotherLinkState,
        `/register/${badgeLabelNr}/email-sent`,
    );

    const [removeBadgeRegistrationState, removeBadgeRegistration] =
        useParkingaboServerWrite<
            {
                badgeLabelNr: string;
                email: string;
            },
            never
        >(() => ({
            url: `/ui-api/parkingabo/badge/${badgeLabelNr}/remove-badge-registration`,
        }));
    useNavigateOnSuccess(
        removeBadgeRegistrationState,
        `/register-with-badge/${badgeLabelNr}`,
    );
    useNavigateOnError(removeBadgeRegistrationState, '/login');

    useServerSuccessEffect(badgeValidationState, data => {
        if (data.isOnboardingComplete) {
            setValidationState(
                ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE,
            );
        } else if (data.hasLogin && !data.emailToBeConfirmed) {
            setValidationState(
                ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING,
            );
        } else if (data.hasLogin) {
            setValidationState(ValidationState.CONFIRM_EMAIL);
            setEmailToBeConfirmed(data.emailToBeConfirmed);
        } else {
            navigate(`/register-with-badge/${badgeLabelNr}`);
        }
    });

    useServerErrorEffect(badgeValidationState, (_, data) => {
        if (data?.reason === ValidationErrorReason.BADGE_NOT_FOUND) {
            setValidationState(ValidationState.BADGE_NOT_FOUND);
        } else {
            setValidationState(ValidationState.GENERIC_ERROR);
        }
    });

    if (badgeLabelNr === null) {
        navigate('/login');
    }

    switch (validationState) {
        case ValidationState.LOADING:
            return (
                <PresetLoadingSpinner
                    preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
                />
            );
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE:
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING:
            return (
                <RedirectToLogin
                    onConfirm={() => navigate('/login')}
                    validationState={validationState}
                />
            );
        case ValidationState.CONFIRM_EMAIL:
            return (
                <ConfirmEmailPopup
                    emailToBeConfirmed={emailToBeConfirmed!}
                    onConfirm={() =>
                        requestAnotherLink({ email: emailToBeConfirmed! })
                    }
                    onAbort={() =>
                        removeBadgeRegistration({
                            badgeLabelNr: badgeLabelNr!,
                            email: emailToBeConfirmed!,
                        })
                    }
                />
            );
        case ValidationState.BADGE_NOT_FOUND:
            return <BadgeNotFoundPopup onConfirm={() => navigate('/login')} />;
        case ValidationState.GENERIC_ERROR:
            return <GenericErrorPopup onConfirm={() => navigate('/login')} />;
        default:
            return null;
    }
}

export function AuthedParkingaboBadgeValidationRoute() {
    const badgeLabelNr = useParamOrNull('badgeLabelNr');
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const [validationState, setValidationState] = useState<ValidationState>(
        ValidationState.LOADING,
    );

    const [badgeValidationState] = useParkingaboServerFetch<
        null,
        { badgeLabelNr: string },
        { reason: ValidationErrorReason }
    >(
        ({ badgeLabelNr }) => ({
            url: `/ui-api/parkingabo/user/badge/${badgeLabelNr}/validation`,
        }),
        badgeLabelNr ? { badgeLabelNr: badgeLabelNr } : null,
    );

    useServerSuccessEffect(badgeValidationState, () => {
        navigate(generateAuthedParkingaboPath('/'));
    });

    useServerErrorEffect(badgeValidationState, (_, data) => {
        if (data === null) {
            setValidationState(ValidationState.GENERIC_ERROR);
        } else {
            switch (data.reason) {
                case ValidationErrorReason.BADGE_NOT_FOUND:
                    setValidationState(ValidationState.BADGE_NOT_FOUND);
                    break;
                case ValidationErrorReason.WRONG_CUSTOMER:
                    setValidationState(ValidationState.WRONG_CUSTOMER);
                    break;
            }
        }
    });

    if (badgeLabelNr === null) {
        navigate(generateAuthedParkingaboPath('/'));
    }

    switch (validationState) {
        case ValidationState.LOADING:
            return (
                <PresetLoadingSpinner
                    preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
                />
            );
        case ValidationState.WRONG_CUSTOMER:
            return (
                <ErrorPopupByValidationErrorReason
                    onConfirm={() => navigate('/login')}
                />
            );
        case ValidationState.BADGE_NOT_FOUND:
            return <BadgeNotFoundPopup onConfirm={() => navigate('/login')} />;
        case ValidationState.GENERIC_ERROR:
            return <GenericErrorPopup onConfirm={() => navigate('/login')} />;
        default:
            return null;
    }
}

export function RedirectToLogin({
    onConfirm,
    validationState,
}: {
    onConfirm: () => void;
    validationState:
        | ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE
        | ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING;
}) {
    switch (validationState) {
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE:
            return (
                <FeedbackPopup
                    open={true}
                    color="success"
                    onConfirm={onConfirm}
                    abortLabel={'OK'}
                    title={
                        <Localized
                            de="Gültiger Badge"
                            fr="Badge valuable"
                            it="Badge valido"
                            en="Valid badge"
                        />
                    }
                >
                    <p>
                        <Localized
                            de="Dieser Badge ist im Parkingabo-System korrekt konfiguriert."
                            fr="Ce badge est correctement configuré dans le système Parkingabo."
                            it="Questo badge è configurato correttamente nel sistema Parkingabo."
                            en="This badge is correctly configured in the Parkingabo system."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Wenn Sie Ihre Konfiguration überprüfen/ändern oder Ihre Transaktionen einsehen möchten, melden Sie sich mit Ihren Zugangsdaten bei Ihrem Parkingabo-Konto an."
                            fr="Si vous souhaitez vérifier/modifier votre configuration ou consulter vos transactions, connectez-vous à votre compte Parkingabo avec vos identifiants."
                            it="Se vuole verificare/modificare la configurazione o consultare le transazioni, acceda al tuo conto Parkingabo con le sue credenziali."
                            en="If you want to check/change your configuration or consult your transactions, log in to your Parkingabo account with your credentials."
                        />
                    </p>
                </FeedbackPopup>
            );
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING:
            return (
                <FeedbackPopup
                    open={true}
                    color="warning"
                    onConfirm={onConfirm}
                    abortLabel={'OK'}
                    title={
                        <Localized
                            de="Konfiguration nicht vollständig"
                            fr="Configuration incomplet"
                            it="Configurazione non completa"
                            en="Configuration not complete"
                        />
                    }
                >
                    <p>
                        <Localized
                            de="Um diesen Badge nutzen zu können, müssen Sie die Konfiguration abschließen, indem Sie sich mit Ihren Zugangsdaten bei Ihrem Parkingabo-Konto anmelden und den Anweisungen folgen."
                            fr="Pour utiliser ce badge, vous devez compléter votre configuration en vous connectant à votre compte Parkingabo avec vos identifiants et en suivant les instructions."
                            it="Per poter utilizzare questo badge, deve completare la configurazione accedendo al suo conto con le sue credenziali e seguendo le istruzioni."
                            en="In order to use this badge, you must complete the configuration by logging into your Parkingabo account using your credentials and following the instructions."
                        />
                    </p>
                </FeedbackPopup>
            );
    }
}

export function ConfirmEmailPopup({
    emailToBeConfirmed,
    onConfirm,
    onAbort,
}: {
    emailToBeConfirmed: string;
    onConfirm: () => void;
    onAbort: () => void;
}) {
    return (
        <FeedbackPopup
            open={true}
            color="warning"
            onConfirm={onConfirm}
            confirmLabel={
                <Localized
                    de="ERNEUT SENDEN"
                    fr="RENVOYER"
                    it="INVIA DI NUOVO"
                    en="RESEND"
                />
            }
            onAbort={onAbort}
            abortLabel={
                <Localized
                    de="ERNEUT REGISTRIEREN"
                    fr="RÉPÉTER L'INSCRIPTION"
                    it="RIPETI ISCRIZIONE"
                    en="REPEAT REGISTRATION"
                />
            }
            title={
                <Localized
                    de="Unbestätigte E-Mail Adresse"
                    fr="Adresse e-mail non confirmée"
                    it="Indirizzo e-mail non confermato"
                    en="Unconfirmed e-mail address"
                />
            }
        >
            <Localized
                de={
                    <p>
                        Sie haben die E-Mail-Adresse <b>{emailToBeConfirmed}</b>{' '}
                        noch nicht bestätigt.
                    </p>
                }
                fr={
                    <p>
                        Vous n&#39;avez pas encore confirmé l&#39;adresse e-mail{' '}
                        <b>{emailToBeConfirmed}</b>.
                    </p>
                }
                it={
                    <p>
                        Non ha ancora confermato l&#39;indirizzo e-mail{' '}
                        <b>{emailToBeConfirmed}</b>.
                    </p>
                }
                en={
                    <p>
                        You have not yet confirmed the email address{' '}
                        <b>{emailToBeConfirmed}</b>.
                    </p>
                }
            />
            <p>
                <Localized
                    de="Sollten Sie die E-Mail-Mitteilung mit dem Bestätigungslink nicht erhalten haben, überprüfen Sie in Ihrem E-Mail-Programm den Spam-Ordner."
                    fr="Veuillez vérifier le dossier spam dans votre programme de messagerie si vous n'avez pas reçu le message e-mail avec le lien de confirmation."
                    it="Se non ha ricevuto il messagio e-mail con il link di conferma, verifichi per favore la cartella spam (posta indesiderata) nel suo programma di posta elettronica."
                    en="Please also check the spam folder in your email program, if you have not received the email message with the confirmation link."
                />
            </p>
            <p>
                <Localized
                    de="Sie können mit «ERNEUT SENDEN» einen neuen Bestätigungslink anfordern oder mit «ERNEUT REGISTRIEREN» die E-Mail-Adresse korrigieren."
                    fr="Vous pouvez demander un nouveau lien de confirmation avec «RENVOYER» ou corriger votre adresse e-mail avec «RÉPÉTER L'INSCRIPTION»."
                    it="Può richiedere un nuovo link di conferma cliccando su «INVIA DI NUOVO» oppure può correggere l'indirizzo e-mail con «RIPETI ISCRIZIONE»."
                    en="You can request a new confirmation link with «RESEND» or you can correct your e-mail address with «REPEAT REGISTRATION»."
                />
            </p>
        </FeedbackPopup>
    );
}

export function ErrorPopupByValidationErrorReason({
    onConfirm,
}: {
    onConfirm: () => void;
}) {
    return (
        <FeedbackPopup
            open={true}
            color="error"
            onConfirm={onConfirm}
            confirmLabel={'OK'}
            title={
                <Localized
                    de="Bereits registriert"
                    fr="Déjà enregistré"
                    it="Già registrato"
                    en="Already registered"
                />
            }
        >
            <p>
                <Localized
                    de="Sie sind bereits bei Parkingabo registriert und können sich daher nicht erneut mit diesem Badge registrieren."
                    fr="Vous êtes déjà enregistré dans Parkingabo et ne pouvez donc pas vous enregistrer à nouveau avec ce badge."
                    it="Siete già registrato in Parkingabo e quindi non è possibile effettuare una nuova registrazione con questo badge."
                    en="You are already registered in Parkingabo and therefore cannot register again with this badge."
                />
            </p>
        </FeedbackPopup>
    );
}

export function BadgeNotFoundPopup({ onConfirm }: { onConfirm: () => void }) {
    return (
        <FeedbackPopup
            open={true}
            color="error"
            onConfirm={onConfirm}
            confirmLabel={'OK'}
            title={
                <Localized
                    de="Badge nicht gefunden"
                    fr="Badge introuvable"
                    it="Badge non trovato"
                    en="Badge not found"
                />
            }
        >
            <p>
                <Localized
                    de="Dieser Badge ist entweder im Parkingabo-System nicht konfiguriert oder kann nicht Online abgefragt werden."
                    fr="Ce badge n'est pas configuré dans le système Parkingabo ou ne peut pas être consulté en ligne."
                    it="Questo badge non è configurato nel sistema Parkingabo oppure non può essere consultato online."
                    en="This badge is either not configured in the Parkingabo system or cannot be accessed online."
                />
            </p>
        </FeedbackPopup>
    );
}

export function GenericErrorPopup({ onConfirm }: { onConfirm: () => void }) {
    return (
        <FeedbackPopup
            open={true}
            color="error"
            onConfirm={onConfirm}
            confirmLabel={'OK'}
            title={
                <Localized
                    de="Es ist ein Fehler aufgetreten."
                    fr="Une erreur est survenue."
                    it="Si è verificato un errore."
                    en="An error has occurred."
                />
            }
        >
            <p>
                <Localized
                    de="Bitte versuchen Sie es in ein paar Minuten erneut."
                    fr="Veuillez réessayer dans quelques minutes."
                    it="Voglia riprovare tra qualche minuto."
                    en="Please try again in a few minutes."
                />
            </p>
        </FeedbackPopup>
    );
}

enum ValidationErrorReason {
    BADGE_NOT_FOUND = 'BADGE_NOT_FOUND',
    WRONG_CUSTOMER = 'WRONG_CUSTOMER',
}
