import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export function StatusBar({
    children,
    rightLabel,
    active,
}: {
    children: ReactNode;
    rightLabel?: ReactNode;
    active?: boolean;
}) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={3}
            sx={{
                width: '100%',
                paddingX: 3,
                paddingY: 0.5,
                backgroundColor: theme =>
                    active
                        ? theme.palette.primary.main
                        : theme.palette.grey[600],
                textTransform: 'uppercase',
            }}
        >
            <Typography
                variant="subtitle2"
                sx={theme => ({ color: theme.palette.primary.contrastText })}
            >
                {children}
            </Typography>
            {rightLabel && (
                <Typography
                    variant="subtitle2"
                    sx={theme => ({
                        color: theme.palette.primary.contrastText,
                    })}
                >
                    {rightLabel}
                </Typography>
            )}
        </Stack>
    );
}
