import { ReactNode, useMemo } from 'react';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { Outlet } from 'react-router-dom';
import { AuthedRouteCompProps } from '../../RouteUtils';
import { Box, Stack, Typography } from '@mui/material';
import {
    ParkingaboListChevron,
    ParkingaboListItem,
} from '../../../components/ParkingaboMenuListItem';
import { Add, LocalParking } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import { Formatter } from 'dg-web-shared/lib/Date';
import { DateTime } from 'luxon';
import { ParkingaboProductState } from '../../../shared/ParkingaboProductModels';
import { StatusBar } from '../../../components/StatusBar';
import { useForceProductVisibility } from '../../../hooks/UseForceProductVisibility.tsx';

export function ProductsRoute({ products, user }: AuthedRouteCompProps) {
    const forceVisibility = useForceProductVisibility();
    const activeProducts = useMemo(
        () =>
            products.filter(
                product => product.state === ParkingaboProductState.ACTIVE,
            ),
        [products],
    );
    const futureProducts = useMemo(
        () =>
            products.filter(
                product => product.state === ParkingaboProductState.FUTURE,
            ),
        [products],
    );
    const expiredProducts = useMemo(
        () =>
            products.filter(
                product => product.state === ParkingaboProductState.EXPIRED,
            ),
        [products],
    );
    return (
        <ParkingaboLayoutWithHeader
            noGutter
            scrollable
            backTo={'..'}
            title={
                <Localized
                    de="Produkte"
                    fr="Produits"
                    it="Prodotti"
                    en="Products"
                />
            }
        >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={0}
            >
                {forceVisibility || user.hasProductAvailable ? (
                    <ParkingaboListItem to={'add'} colorVariant="primary">
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={3}
                            sx={{ width: '100%' }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexGrow: 1,
                                    textTransform: 'uppercase',
                                }}
                            >
                                <Localized
                                    de="Produkt hinzufügen"
                                    fr="Ajouter un produit"
                                    it="Aggiungi prodotto"
                                    en="Add product"
                                />
                            </Box>
                            <Add style={{ fontSize: 36 }} color={'primary'} />
                        </Stack>
                    </ParkingaboListItem>
                ) : (
                    <Box sx={{ height: '10px', width: '100%' }} />
                )}
                <StatusBar active>
                    <Localized
                        de="Gültige"
                        fr="Valables"
                        it="Validi"
                        en="Valid"
                    />
                </StatusBar>
                {activeProducts.map(product => (
                    <ParkingaboListItem
                        to={product.contractId.toString(10)}
                        key={product.contractId}
                        variableHeight
                    >
                        <ProductElement>
                            <Box>
                                <Typography
                                    component="h2"
                                    fontWeight="bold"
                                    paddingBottom={1}
                                >
                                    <Localized {...product.name} />
                                </Typography>
                                {product.zones.length < 3 ? (
                                    product.zones.map(zone => (
                                        <Typography
                                            variant="body2"
                                            noWrap
                                            key={zone.zoneId}
                                        >
                                            {zone.name}
                                        </Typography>
                                    ))
                                ) : (
                                    <Typography variant="body2">
                                        {product.zones.length}{' '}
                                        <Localized
                                            de="Parkings"
                                            fr="parkings"
                                            it="parcheggi"
                                            en="parkings"
                                        />
                                    </Typography>
                                )}
                                <Typography variant="body2">
                                    {product.validTo ? (
                                        <>
                                            <Localized
                                                de="bis"
                                                fr="jusqu'au"
                                                it="fino al"
                                                en="to"
                                            />{' '}
                                            {Formatter.dayMonthYearHourMinute(
                                                DateTime.fromISO(
                                                    product.validTo,
                                                ),
                                            )}
                                        </>
                                    ) : (
                                        <Localized
                                            de="bis auf Widerruf"
                                            fr="jusqu'à révocation"
                                            it="fino a revoca"
                                            en="until recall"
                                        />
                                    )}
                                </Typography>
                            </Box>
                        </ProductElement>
                    </ParkingaboListItem>
                ))}
                {activeProducts.length < 1 && (
                    <ParkingaboListItem>
                        <Typography
                            color="text.disabled"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexGrow: 1,
                            }}
                        >
                            <Localized
                                de="Keine gültigen Produkte"
                                fr="Aucun produit valable"
                                it="Nessun prodotto valido"
                                en="No valid products"
                            />
                        </Typography>
                    </ParkingaboListItem>
                )}
                {futureProducts.length > 0 && (
                    <>
                        <StatusBar>
                            <Localized
                                de="Zukünftig gültige"
                                fr="Valables dans le futur"
                                it="Validi in futuro"
                                en="Valid in the future"
                            />
                        </StatusBar>
                        {futureProducts.map(product => (
                            <ParkingaboListItem
                                to={product.contractId.toString(10)}
                                key={product.contractId}
                            >
                                <ProductElement disabled>
                                    <Box>
                                        <Typography
                                            component="h2"
                                            fontWeight="bold"
                                        >
                                            <Localized {...product.name} />
                                        </Typography>
                                        <Typography variant="body2">
                                            <Localized
                                                de="ab"
                                                fr="à partir du"
                                                it="dal"
                                                en="from"
                                            />{' '}
                                            {Formatter.dayMonthYearHourMinute(
                                                DateTime.fromISO(
                                                    product.validFrom,
                                                ),
                                            )}
                                        </Typography>
                                    </Box>
                                </ProductElement>
                            </ParkingaboListItem>
                        ))}
                    </>
                )}
                {expiredProducts.length > 0 && (
                    <>
                        <StatusBar
                            rightLabel={
                                <Localized
                                    de="letzte 30 Tage"
                                    fr="derniers 30 jours"
                                    it="ultimi 30 giorni"
                                    en="last 30 days"
                                />
                            }
                        >
                            <Localized
                                de="Abgelaufene"
                                fr="Échus"
                                it="Scaduti"
                                en="Expired"
                            />
                        </StatusBar>
                        {expiredProducts.map(product => (
                            <ParkingaboListItem
                                to={product.contractId.toString(10)}
                                key={product.contractId}
                            >
                                <ProductElement disabled>
                                    <Typography
                                        component="h2"
                                        fontWeight="bold"
                                        color="text.disabled"
                                    >
                                        <Localized {...product.name} />
                                    </Typography>
                                </ProductElement>
                            </ParkingaboListItem>
                        ))}
                    </>
                )}
            </Stack>
            <Outlet />
        </ParkingaboLayoutWithHeader>
    );
}

function ProductElement({
    disabled,
    children,
}: {
    disabled?: boolean;
    children: ReactNode;
}) {
    const color = (theme: Theme) =>
        disabled ? theme.palette.text.disabled : theme.palette.primary.main;
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={3}
            sx={{ width: '100%' }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0,
                    color: color,
                }}
            >
                <LocalParking />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                    color: color,
                }}
            >
                {children}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0,
                }}
            >
                <ParkingaboListChevron disabled={disabled} />
            </Box>
        </Stack>
    );
}
