import { stepLabelClasses, ThemeOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const corporateBlueDarkDark = '#071a3d';
const corporateBlueDark = '#0e306c';
const corporateBlueLight = '#e7ebf6';
const corporateGreyLight = '#00000080';
const rootFontSize = 16;
const globalLetterSpacing = '0.03em';
const body2FontSize = 14;

export const parkingaboDefaultMuiTheme: ThemeOptions = createTheme({
    typography: {
        fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        fontSize: rootFontSize,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontWeight: 700,
            fontSize: 34,
            letterSpacing: globalLetterSpacing,
        },
        h2: {
            fontSize: 22,
            fontWeight: 700,
            letterSpacing: globalLetterSpacing,
        },
        h3: {
            fontSize: 18,
            fontWeight: 700,
            letterSpacing: globalLetterSpacing,
        },
        h4: {
            letterSpacing: globalLetterSpacing,
        },
        h5: {
            letterSpacing: globalLetterSpacing,
        },
        h6: {
            letterSpacing: globalLetterSpacing,
        },
        subtitle1: {
            letterSpacing: globalLetterSpacing,
        },
        subtitle2: {
            fontSize: body2FontSize,
            fontWeight: 500,
            letterSpacing: globalLetterSpacing,
        },
        body1: {
            fontSize: rootFontSize,
            letterSpacing: globalLetterSpacing,
        },
        body2: {
            fontSize: body2FontSize,
            letterSpacing: globalLetterSpacing,
        },
        button: {
            letterSpacing: globalLetterSpacing,
        },
        caption: {
            letterSpacing: globalLetterSpacing,
        },
        overline: {
            fontSize: 12,
            lineHeight: 1.5,
            fontWeight: 500,
            color: corporateGreyLight,
            letterSpacing: globalLetterSpacing,
        },
    },
    palette: {
        common: {
            black: '#000',
            white: '#fff',
        },
        primary: {
            dark: corporateBlueDarkDark,
            main: corporateBlueDark,
            light: corporateBlueLight,
            contrastText: '#fff',
        },
        secondary: {
            dark: corporateBlueDark,
            main: '#0976c6',
            light: corporateBlueLight,
            contrastText: '#fff',
        },
        error: {
            dark: '#600000',
            main: '#A60000',
            light: '#e19f9f',
            contrastText: '#fff',
        },
        warning: {
            dark: '#7c4900',
            main: '#FFAE00',
            light: '#d0af90',
            contrastText: '#fff',
        },
        info: {
            dark: corporateBlueDarkDark,
            main: corporateBlueDark,
            light: corporateBlueLight,
            contrastText: '#fff',
        },
        success: {
            dark: '#489a2f',
            main: '#78c459',
            light: '#c5efb5',
            contrastText: '#fff',
        },
        text: {
            primary: corporateBlueDark,
            secondary: 'rgba(0,0,0,0.6)',
            disabled: 'rgba(0,0,0,0.38)',
        },
        background: {
            paper: '#fff',
            default: '#fff',
        },
    },
    shape: {
        borderRadius: 0,
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                },
            },
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: ({ ownerState: { fullWidth } }) => ({
                    ...(fullWidth && {
                        borderRadius: 8,
                    }),
                }),
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: 20,
                    margin: 0,
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: ({ ownerState: { variant, color } }) => ({
                    ...(variant === 'standard' &&
                        color === 'info' && {
                            boxShadow: '0px 2px 8px 1px #aaaaaa',
                            backgroundColor: 'none',
                            borderRadius: 16,
                            borderTopRightRadius: 0,
                            color: '#32496B',

                            '& .MuiAlert-icon': {
                                display: 'none',
                            },
                        }),
                }),
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    fontWeight: 700,
                },
                label: {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                variant: 'standard',
                InputLabelProps: {
                    shrink: true,
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    [`&.${stepLabelClasses.alternativeLabel}`]: {
                        marginTop: 10,
                    },
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    fontWeight: 500,
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: corporateBlueLight,
                },
            },
            defaultProps: {
                elevation: 0,
            },
        },
    },
});
